<template>
  <modal
    name="updateConversationModal"
    transition="pop-out"
    class="update-conversation-modal"
    :width="500"
    :focus-trap="true"
    :height="700"
  >
    <div class="position-relative modal-outer-container">
      <div class="modal-header border-bottom">
        <div>Edit Conversation</div>
        <button class="btn border-0" @click="closeModal">
          <i class="fas fa-times fa-lg"></i>
        </button>
      </div>
      <div class="body-inner-container">
        <div class="card-body">
          <form
            @submit.prevent="updateConversationHelper"
            validate
            class="form"
          >
            <div class="row">
              <div class="conversation-avatar shadow-card">
                <div
                  class="avatar-overlay"
                  @click="triggerFileInput"
                  v-if="
                    !updateConversationAvatarLoading && canUpdateConversation
                  "
                >
                  <input
                    type="file"
                    class="d-none avatar-input"
                    accept="image/*"
                    ref="avatarInput"
                    @change="updateAvatar"
                  />
                  <i class="fas fa-camera fa-2x text-white"></i>
                </div>
                <div class="loader" v-else-if="updateConversationAvatarLoading">
                  <i class="fas fa-spinner fa-spin fa-2x"></i>
                </div>
                <img :src="conversation.avatar || profileImagePlaceholder" />
              </div>
              <div class="col-12 mb-3">
                <label class="col-12 font-weight-bold pt-2 pl-0">
                  Conversation Title
                </label>
                <div class="d-flex justify-content-center align-items-center">
                  <input
                    type="text"
                    class="form-control mr-2"
                    placeholder="Conversation title"
                    v-model="title"
                    required
                  />
                  <button
                    class="btn btn-theme w-25"
                    type="submit"
                    v-if="canUpdateConversation"
                  >
                    <i
                      class="fas fa-spin fa-circle-notch"
                      v-if="updateConversationLoading"
                    ></i>
                    <span v-else> Update </span>
                  </button>
                </div>
              </div>
              <div class="col-12 mb-1">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search by participant name"
                  v-model="searchKey"
                />
              </div>
              <div class="col-12">
                <ul class="participant-list border mt-2 rounded">
                  <li
                    class="text-center p-3 h3 m-0"
                    v-if="!filteredParticipants.length && searchKey"
                  >
                    No participants found.
                  </li>
                  <li
                    class="text-center p-3 h3 m-0"
                    v-if="!filteredParticipants.length && !searchKey"
                  >
                    No participants to search for.
                  </li>
                  <li
                    v-for="participant in filteredParticipants"
                    :key="participant.id"
                  >
                    <div class="d-flex align-items-center">
                      <div class="user-image-with-status">
                        <img
                          :src="
                            participant.user.avatar || profileImagePlaceholder
                          "
                          class="user-image"
                        />
                        <div
                          class="user-status"
                          :class="{
                            online: isOnline(participant.user),
                            offline: !isOnline(participant.user),
                          }"
                        />
                      </div>
                      <div class="d-flex flex-column">
                        <span class="font-weight-bold">
                          {{ participant.name }}
                        </span>
                        <span class="text-muted">
                          {{ participant.role }}
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { ConversationParticipantType } from "../../utils/Enum";

export default {
  name: "UpdateConversationModal",
  props: {
    conversation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      title: "",
      participants: [],
      searchKey: "",
    };
  },
  mounted() {
    this.title = this.conversation.title;
    this.participants = this.conversation.participants;
    this.searchKey = "";
  },
  computed: {
    ...mapState({
      updateConversationLoading: (state) =>
        state.chat.updateConversationLoading,
      updateConversationAvatarLoading: (state) =>
        state.chat.updateConversationAvatarLoading,
      loggedInUser: (state) => state.auth.user,
      onlineUsers: (state) => state.auth.onlineUsers,
    }),
    filteredParticipants: function () {
      return this.participants.filter((participant) => {
        return participant.name
          .toLowerCase()
          .includes(this.searchKey.toLowerCase());
      });
    },
    profileImagePlaceholder: function () {
      return require("@/assets/img/avatars/profiles/avatar.jpg");
    },
    canUpdateConversation: function () {
      return (
        this.conversation?.participant?.role ==
        ConversationParticipantType.ADMIN
      );
    },
  },
  methods: {
    ...mapActions({
      updateConversation: "chat/updateConversation",
      updateConversationAvatar: "chat/updateConversationAvatar",
      storeConversationMessage: "chat/storeConversationMessage",
    }),
    updateConversationHelper: function () {
      const oldTitle = this.conversation.title;
      this.updateConversation({
        conversationId: this.conversation.id,
        data: {
          title: this.title,
        },
      }).then((res) => {
        if (res) {
          if (String(oldTitle).trim() != String(this.title).trim()) {
            this.$emit("conversation-updated", res.data.data);
          }
          this.closeModal();
        }
      });
    },
    triggerFileInput: function () {
      this.$refs.avatarInput.click();
    },
    updateAvatar: function (e) {
      const file = e.target.files[0];
      this.updateConversationAvatar({
        conversationId: this.conversation.id,
        data: {
          avatar: file,
        },
      }).then((res) => {
        if (res) {
          this.$emit("conversation-avatar-updated", res.data.data);
        }
      });
    },
    closeModal: function () {
      this.title = this.conversation.title;
      this.participants = this.conversation.participants;
      this.searchKey = "";
      this.$modal.hide("updateConversationModal");
    },
    isOnline: function (user) {
      return (this.onlineUsers || []).includes(user.id);
    },
  },
};
</script>
<style lang="scss" scoped>
@import url("./_conversation.scss");
.update-conversation-modal {
  .conversation-avatar {
    position: relative;
    width: 150px;
    height: 150px;
    margin: 0 auto;
    border-radius: 100%;
    overflow: hidden;
    .avatar-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      i {
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.3s ease-in-out;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.5);
        i {
          opacity: 1;
        }
      }
    }
    .loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.5);
      i {
        color: #fff;
      }
    }
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .participant-list {
    max-height: 320px;
  }
}
</style>
<style lang="scss">
.update-conversation-modal {
  .v--modal-background-click {
    display: flex;
    align-items: center;
  }
  .v--modal {
    max-height: 90vh;
    top: 0 !important;
  }
  &.v--modal-overlay {
    z-index: 1002;
  }
  .body-inner-container {
    overflow-x: hidden;
    overflow-y: auto;
    .form-group {
      margin-bottom: 0.4rem;
    }
  }
}
</style>

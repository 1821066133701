<template>
  <modal
    name="conversationUsersModal"
    transition="pop-out"
    class="conversation-users-modal"
    :width="500"
    :focus-trap="true"
    :height="500"
  >
    <div class="position-relative modal-outer-container">
      <div class="modal-header border-bottom">
        <div class="ml-3 pl-2">
          {{ modalType == "remove" ? "Remove" : "Add" }} Conversation
          Participants
        </div>
        <button class="btn border-0" @click="closeModal">
          <i class="fas fa-times fa-lg"></i>
        </button>
      </div>
      <div class="body-inner-container">
        <div class="card-body">
          <div class="col-12 mb-1">
            <input
              type="text"
              class="form-control"
              :placeholder="`Search by ${
                modalType == 'remove' ? 'participant' : 'user'
              } name`"
              v-model="searchKey"
            />
          </div>
          <div class="col-12">
            <ul
              class="participant-list border mt-2 rounded"
              v-if="modalType == 'remove'"
            >
              <li
                class="text-center p-3 h3 m-0"
                v-if="!filteredParticipants.length && searchKey"
              >
                No participant found.
              </li>
              <li
                class="text-center p-3 h3 m-0"
                v-if="!filteredParticipants.length && !searchKey"
              >
                No participants to remove.
              </li>
              <li
                v-for="participant in filteredParticipants"
                :key="participant.id"
              >
                <div class="d-flex align-items-center">
                  <div class="user-image-with-status">
                    <img
                      :src="participant.user.avatar || profileImagePlaceholder"
                      class="user-image"
                    />
                    <div
                      class="user-status"
                      :class="{
                        online: isOnline(
                          participant.user && participant.user.id
                        ),
                        offline: !isOnline(
                          participant.user && participant.user.id
                        ),
                      }"
                    />
                  </div>
                  <div class="d-flex flex-column">
                    <span class="font-weight-bold">
                      {{ participant.name }}
                    </span>
                    <span class="text-muted">
                      {{ participant.role }}
                    </span>
                  </div>
                  <div class="ml-auto">
                    <button
                      type="button"
                      class="btn btn-outline-danger"
                      @click="removeParticipant(participant)"
                      v-if="isParticipant(participant)"
                    >
                      <i
                        class="fas fa-spin fa-circle-notch"
                        v-if="
                          removeConversationParticipantLoading == participant.id
                        "
                      ></i>
                      <i class="fas fa-minus" v-else></i>
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="participant-list border mt-2 rounded" v-else>
              <li
                class="text-center p-3 h3 m-0"
                v-if="!usersLoading && !filteredUsers.length && searchKey"
              >
                No users found.
              </li>
              <li
                class="text-center p-3 h3 m-0"
                v-if="!usersLoading && !filteredUsers.length && !searchKey"
              >
                No users to add.
              </li>
              <li v-for="user in filteredUsers" :key="user.user_id">
                <div class="d-flex align-items-center">
                  <div class="user-image-with-status">
                    <img
                      :src="user.profile_picture || profileImagePlaceholder"
                      class="user-image"
                    />
                    <div
                      class="user-status"
                      :class="{
                        online: isOnline(user.user_id),
                        offline: !isOnline(user.user_id),
                      }"
                    />
                  </div>
                  <div class="d-flex flex-column">
                    <span class="font-weight-bold">
                      {{ user.name }}
                    </span>
                    <span class="text-muted small font-weight-bold">
                      {{ getRoleName(user.roleId) }}
                    </span>
                  </div>
                  <div class="ml-auto">
                    <button
                      type="button"
                      class="btn btn-outline-blue"
                      @click="addParticipant(user)"
                      v-if="!isParticipant(user)"
                    >
                      <i
                        class="fas fa-spin fa-circle-notch"
                        v-if="addConversationParticipantLoading == user.user_id"
                      ></i>
                      <i class="fas fa-plus" v-else></i>
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "AddParticipantModal",
  props: {
    conversation: {
      type: Object,
      required: true,
    },
    modalType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      searchKey: "",
    };
  },
  computed: {
    ...mapState({
      allUsers: (state) => state.security.usersWithRoles,
      usersLoading: (state) => state.security.usersWithRolesLoading,
      loggedInUser: (state) => state.auth.user,
      addConversationParticipantLoading: (state) =>
        state.chat.addConversationParticipantLoading,
      removeConversationParticipantLoading: (state) =>
        state.chat.removeConversationParticipantLoading,
      onlineUsers: (state) => state.auth.onlineUsers,
    }),
    filteredUsers: function () {
      return this.allUsers
        .filter(
          (user) =>
            user.user_id != this.loggedInUser.id &&
            (user.status == "ACTIVE" || user.status == "PROTECTED") &&
            !this.isParticipant(user)
        )
        .filter((user) => {
          return (
            user.name.toLowerCase().indexOf(this.searchKey.toLowerCase()) !== -1
          );
        });
    },
    filteredParticipants: function () {
      return this.conversation.participants
        .filter((participant) => participant.user.id != this.loggedInUser.id)
        .filter((participant) => {
          return (
            participant.name
              .toLowerCase()
              .indexOf(this.searchKey.toLowerCase()) !== -1
          );
        });
    },
    profileImagePlaceholder: function () {
      return require("@/assets/img/avatars/profiles/avatar.jpg");
    },
  },
  methods: {
    ...mapActions({
      addConversationParticipant: "chat/addConversationParticipant",
      removeConversationParticipant: "chat/removeConversationParticipant",
    }),
    closeModal: function () {
      this.$modal.hide("conversationUsersModal");
    },
    addParticipant(participant) {
      this.addConversationParticipant({
        conversationId: this.conversation.id,
        data: {
          participant_id: participant.user_id,
        },
      }).then((res) => {
        if (res) {
          this.$emit("conversation-participant-added", {
            conversation: res.data.data,
            participant: participant,
          });
        }
      });
    },
    removeParticipant(participant) {
      this.removeConversationParticipant({
        conversationId: this.conversation.id,
        participantId: participant.id,
      }).then((res) => {
        if (res) {
          this.$emit("conversation-participant-removed", {
            conversation: res.data.data,
            participant: participant,
          });
        }
      });
    },
    isParticipant: function (user) {
      if (this.modalType == "remove") {
        return this.conversation.participants.some(
          (participant) => participant.id == user.id
        );
      } else {
        return this.conversation.participants.some(
          (participant) => participant.user.id == user.user_id
        );
      }
    },
    isOnline: function (user_id) {
      return (this.onlineUsers || []).includes(user_id);
    },
    getRoleName: function (roleId) {
      let roleName;
      switch (roleId) {
        case 1:
          roleName = "(Admin)";
          break;
        case 2:
          roleName = "(Provider)";
          break;
        case 5:
          roleName = "(Manager)";
          break;
        default:
          roleName = "(Client)";
          break;
      }
      return roleName;
    },
  },
};
</script>
<style lang="scss" scoped>
.conversation-users-modal {
  &.v--modal-overlay {
    z-index: 1002;
  }
}
@import url("./_conversation.scss");
</style>

<template>
  <div>
    <div v-if="loading">
      <alert class="my-2" />
    </div>
    <alert class="my-2" v-if="!loading && events.length == 0"
      >No Upcoming Events</alert
    >
    <div
      class="card mb-0 mobile-tablet-no-card"
      v-if="!loading && events.length > 0"
    >
      <div class="card mb-0 mobile-tablet-no-card table-container">
        <table
          class="
            table table-sm table-striped
            mb-0
            responsive-mobile-tablet-table
            p-2 p-lg-0
          "
        >
          <thead>
            <tr>
              <th>Start Date</th>
              <th>End Date</th>
              <th v-if="user && !user.isProvider">Provider</th>
              <th v-if="user && !user.isPatient">Client</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="task in events" :key="task.id">
              <td>
                <b class="mobile-tablet-only mr-2">Start Date:</b>
                {{ formattedDate(task.start_time) }}
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">End Date:</b>
                {{ formattedDate(task.end_time) }}
              </td>
              <td v-if="user && !user.isProvider">
                <b class="mobile-tablet-only mr-2">Provider:</b>
                <span v-html="task.provider_name || 'N/A'"></span>
              </td>
              <td v-if="user && !user.isPatient">
                <b class="mobile-tablet-only mr-2">Client:</b>
                <span v-html="task.patient_name || 'N/A'"></span>
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Type:</b>{{ task.type }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import * as dayjs from "dayjs";
import { orderBy } from "lodash";
import dateFormat from "@/utils/dateFormat";
import Swal from "sweetalert2";
import { mapState } from "vuex";

export default {
  name: "appointments",
  props: ["maxItems"],
  data() {
    return {
      loading: true,
      events: [],
    };
  },
  created() {
    this.getApts();
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  methods: {
    getApts: function () {
      this.events = [];
      let requestUrl = `users/${this.user.id}/appointments`;

      let startDateTime = `${dateFormat(
        new Date(),
        "YYYY-MM-DD"
      )}T00:00:00.000000Z`;
      const canadaOffsetStart = dayjs
        .tz(new Date(startDateTime), "America/Toronto")
        .utcOffset();

      startDateTime = dayjs(startDateTime)
        .add(canadaOffsetStart, "minute")
        .utc()
        .format();
      requestUrl = `${requestUrl}?start-time=${startDateTime}&size=${this.maxItems}`;
      this.$http
        .get(requestUrl)
        .then(
          function (res) {
            let vm = this;
            vm.events = [];
            if (res && res.data.data) {
              const futureEvents = res.data.data.filter((event) =>
                this.displayPerUserType(event)
              );
              this.events = orderBy(futureEvents, ["start_time"], ["asc"]);
              if (this.maxItems) {
                this.events = this.events.slice(0, this.maxItems);
              }
            }
            this.loading = false;
          }.bind(this)
        )
        .catch((err) => {
          if (!err.accessDenied) {
            Swal.fire({
              title: "Error",
              text: (err.data || {}).message || "Something went wrong...",
              icon: "error",
            });
          }
        });
    },
    dateFormat: dateFormat,
    formattedDate: function (dateToformat) {
      if (dateToformat && dateToformat != "") {
        return dayjs(dateToformat)
          .add(-1 * dayjs(dateToformat).utcOffset(), "minute")
          .format("YYYY-MM-DD hh:mm A");
      } else return "";
    },
    displayPerUserType: function (event) {
      if (this.user.isAdmin || this.user.isManagingAdmin) {
        return true;
      } else if (this.user.isProvider) {
        return event.provider_id == this.user.id;
      } else {
        return event.patient_id == this.user.id;
      }
    },
  },
};
</script>

<template>
  <div class="select-outer-container">
    <div
      class="form-group row"
      :class="`${styleClass.parentElement} ${wrapClass}`"
    >
      <label
        class="col-2 col-form-label"
        :class="`${styleClass.label} ${labelClass}`"
        :for="inputId"
      >
        <slot></slot>
      </label>
      <div class="col">
        <select
          :id="inputId"
          :class="`form-control ${inputClass}`"
          :value="value"
          @input="update($event.target.value)"
          :required="required"
          :disabled="disabled"
        >
          <option
            :class="i.class"
            v-for="i in options"
            :key="i.value"
            :value="i.value"
            v-text="i.label"
          ></option>
        </select>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SelectControl",
  props: {
    value: {
      type: [String, Boolean, Number],
    },
    type: {
      type: String,
    },
    labelClass: {
      type: String,
      default: "",
    },
    inputClass: {
      type: String,
      default: "",
    },
    wrapClass: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    inputId: function () {
      let id = this.$slots.default
        ? this.$slots.default[0].text.toLowerCase().replace(/[^a-z0-9]+/gi, "-")
        : Math.random();
      return `select-control-${id}`;
    },
  },
  data() {
    return {
      styleClass: {
        parentElement: "",
        label: "",
        input: "",
      },
    };
  },
  mounted() {
    if (this.type == "horizontal") {
      this.styleClass.label = "";
      this.styleClass.input = "col";
    } else if (this.type == "vertical") {
      this.styleClass.parentElement = "flex-column";
      this.styleClass.label = "col-12";
      this.styleClass.input = "";
    }
  },

  methods: {
    update: function (value) {
      this.$emit("input", value);
    },
  },
};
</script>

<template>
  <div class="col-12 chartContainer dashboard-item-container">
    <div class="row m-0 justify-content-between dashboard-item-header mb-5">
      <div class="dashboard-item-title">
        Upcoming Appointments
        <sup class="dashboard-selected-time"
          >{{ dateInfo.intervalLabel.start }}-{{
            dateInfo.intervalLabel.end
          }}</sup
        >
      </div>
      <div>
        <div
          class="see-all btn see-all-btn btn-prev"
          :class="`${
            loading || dateInfo.index <= 0 ? 'disabled' : 'pointer-cursor'
          }`"
          @click="!loading && dateInfo.index > 0 && getApts(-1)"
        >
          <i class="fas fa-chevron-left"></i>
        </div>
        <div
          class="see-all btn see-all-btn btn-next mr-3"
          :class="`${loading ? 'disabled' : 'pointer-cursor'}`"
          @click="!loading && getApts(1)"
        >
          <i class="fas fa-chevron-right"></i>
        </div>
        <div
          class="see-all pointer-cursor btn see-all-btn"
          @click="navigateToCalender"
        >
          See All <i class="fas fa-chevron-right"></i>
        </div>
      </div>
    </div>
    <div class="d-flex appointments-semi-calendar">
      <div v-if="loading" class="apt-loader pt-5 mt-5">
        <alert class="mt-5 apt-alert" :emptySlot="true" />
      </div>
      <div
        v-for="label in dateInfo.labels"
        :key="label"
        class="event-outer-container"
      >
        <div class="title">
          {{
            new Date(label).toLocaleDateString("en-US", {
              month: "long",
              day: "numeric",
            })
          }}
        </div>
        <div class="event-container" v-if="events[label] && events[label][0]">
          <div class="polit"></div>
          <div
            class="client-name one-line"
            :title="getPatientName(events[label][0])"
          >
            {{ getPatientName(events[label][0]) }}
          </div>
          <div
            class="provider-name one-line"
            :title="getProviderName(events[label][0])"
          >
            {{ getProviderName(events[label][0]) }}
          </div>
          <div class="event-time">
            {{ formattedDate(events[label][0].start_time) }} -
            {{ formattedDate(events[label][0].end_time) }}
          </div>
        </div>
        <div
          class="more-events-container radius-99 pointer-cursor"
          @click="navigateToCalender"
          v-if="events[label] && events[label].length > 1"
        >
          <strong>+{{ events[label].length - 1 }}</strong> more appointments
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as dayjs from "dayjs";
import { orderBy } from "lodash";
import dateFormat from "@/utils/dateFormat";
import Swal from "sweetalert2";
import { mapState } from "vuex";

export default {
  name: "dashboard-appointments",
  props: ["maxItems"],
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  created() {
    this.getApts();
  },
  methods: {
    getApts: function (stepDirection = 0) {
      this.dateInfo.index += stepDirection;
      this.loading = true;
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      this.events = [];
      var requestUrl = `users/${this.user.id}/appointments`;
      if (!stepDirection) {
        let startDateTime = `${dateFormat(
          new Date(),
          "YYYY-MM-DD"
        )}T00:00:00.000000Z`;

        let EndDateTime = `${dateFormat(
          new Date(),
          "YYYY-MM-DD"
        )}T23:59:59.000000Z`;
        const canadaOffsetStart = dayjs
          .tz(new Date(startDateTime), "America/Toronto")
          .utcOffset();

        this.dateInfo.start = dayjs(startDateTime)
          .add(canadaOffsetStart, "minute")
          .utc();

        this.dateInfo.end = dayjs(EndDateTime)
          .add(canadaOffsetStart, "minute")
          .add(4, "day")
          .utc();
      } else {
        this.dateInfo.start = this.dateInfo.start.add(stepDirection * 5, "day");
        this.dateInfo.end = this.dateInfo.end.add(stepDirection * 5, "day");
      }

      const labels = [];

      const canadaOffsetStart = dayjs
        .tz(new Date(this.dateInfo.start), "America/Toronto")
        .utcOffset();
      const canadaOffsetEnd = dayjs
        .tz(new Date(this.dateInfo.end), "America/Toronto")
        .utcOffset();
      const localStartDate = new Date(
        this.dateInfo.start
          .add(-canadaOffsetStart, "minute")
          .add(-1 * dayjs(this.dateInfo.start).utcOffset(), "minute")
      );
      const localEndDate = new Date(
        this.dateInfo.end
          .add(-1, "day")
          .add(-canadaOffsetEnd, "minute")
          .add(-1 * dayjs(this.dateInfo.end).utcOffset(), "minute")
      );
      const isSameMonth = localStartDate.getMonth() == localEndDate.getMonth();

      this.dateInfo.intervalLabel = {
        start: `${
          monthNames[localStartDate.getMonth()]
        } ${localStartDate.getDate()}`,
        end: `${
          isSameMonth ? "" : monthNames[localEndDate.getMonth()] + " "
        }${localEndDate.getDate()}`,
      };

      for (let i = 0; i < 5; i++) {
        const labelName = new Date(
          dayjs(this.dateInfo.start)
            .add(-canadaOffsetStart, "minute")
            .add(i, "day")
            .add(-1 * dayjs(this.dateInfo.start).utcOffset(), "minute")
        )
          .toISOString()
          .split("T")[0]; // Extracts the date part

        labels.push(labelName);
      }
      this.dateInfo.labels = labels;

      requestUrl = `${requestUrl}?start=${this.dateInfo.start.format()}&end=${this.dateInfo.end.format()}`;
      if (this.maxItems) {
        requestUrl = `${requestUrl}&size=${this.maxItems}`;
      }
      this.$http
        .get(requestUrl)
        .then(
          function (res) {
            let vm = this;
            vm.events = [];
            if (res && res.data.data) {
              const futureEvents = res.data.data.filter((event) =>
                this.displayPerUserType(event)
              );
              this.events = this.groupEvents(
                orderBy(futureEvents, ["start_time"], ["asc"])
              );
            }
            this.loading = false;
          }.bind(this)
        )
        .catch((err) => {
          if (!err.accessDenied) {
            Swal.fire({
              title: "Error",
              text: (err.data || {}).message || "Something went wrong...",
              icon: "error",
            });
          }
        });
    },
    dateFormat: dateFormat,
    getPatientName: function (task) {
      return task.patient_name;
    },
    getProviderName: function (task) {
      return task.provider_name;
    },
    formattedDate: function (dateToformat) {
      if (dateToformat && dateToformat != "") {
        return dayjs(dateToformat)
          .add(-1 * dayjs(dateToformat).utcOffset(), "minute")
          .format("hh:mm A");
      } else return "";
    },
    displayPerUserType: function (event) {
      if (this.user.isAdmin || this.user.isManagingAdmin) {
        return true;
      } else if (this.user.isProvider) {
        return event.provider_id == this.user.id;
      } else {
        return event.patient_id == this.user.id;
      }
    },
    navigateToCalender: function () {
      if (this.user.isAdmin || this.user.isManagingAdmin) {
        this.$router.push({ path: "/admin/profile?event=true" });
      } else if (this.user.isProvider) {
        this.$router.push({
          path: `/providers/${this.user.provider_id}?event=true`,
        });
      } else {
        this.$router.push({
          path: `/clients/${this.user.patient_id}?event=true`,
        });
      }
    },
    groupEvents: function (events) {
      return events.reduce((acc, current) => {
        const canadaOffsetStart = dayjs
          .tz(new Date(current.start_time), "America/Toronto")
          .utcOffset();
        const date = new Date(
          dayjs(current.start_time)
            .add(-canadaOffsetStart, "minute")
            .add(-1 * dayjs(current.start_time).utcOffset(), "minute")
        )
          .toISOString()
          .split("T")[0]; // Extracts the date part
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(current);
        return acc;
      }, {});
    },
  },
  data() {
    return {
      loading: true,
      dateInfo: {
        index: 0,
        start: null,
        end: null,
        labels: [],
        intervalLabel: {
          start: "",
          end: "",
        },
      },
      events: [],
    };
  },
};
</script>

<style lang="scss" scoped>
.btn-prev {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right-color: #d6cdcd78;
}

.btn-next {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-left-color: #d6cdcd78;
}

.appointments-semi-calendar {
  overflow: auto;
}

.event-outer-container {
  border-left: 1px solid var(--secondary-color);
  flex: 0 0 20%;
  max-width: 20%;
  padding-right: 16px;
  min-height: 200px;
  min-width: 200px;

  .title {
    padding-left: 16px;
    padding-right: 4px;
    color: var(--main-text-color);
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 16px;
  }

  .event-container {
    background-color: white;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    border-top-right-radius: 99px;
    border-bottom-right-radius: 99px;
    padding: 16px 32px 12px 16px;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  .polit {
    width: 16px;
    min-width: 16px;
    height: 16px;
    border-radius: 4px;
    margin-bottom: 4px;
    background-color: var(--theme-color);
    color: var(--btn-text-color);
    filter: brightness(0.6);
  }
  .client-name {
    color: var(--main-text-color);
    font-weight: 500;
    font-size: 18px;
  }
  .provider-name {
    font-size: 16px;
    margin-top: 4px;
    margin-bottom: 4px;
    color: var(--theme-color);
    filter: brightness(0.6);
  }

  .event-time {
    font-size: 14px;
    color: var(--theme-color);
    filter: brightness(0.7);
  }

  .more-events-container {
    padding: 4px 8px;
    background-color: white;
    margin-top: 24px;
    margin-bottom: 8px;
    color: var(--main-text-color);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 8px;
    font-size: 16px;
    width: 100%;
    word-break: break-all;
    transition: all 0.3s ease-in-out;

    &:hover {
      filter: brightness(0.9);
    }
  }
}
</style>

<style lang="scss">
.apt-loader {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .apt-alert {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 12px;

    .spinner-border {
      width: 64px;
      height: 64px;
    }
  }
}
</style>

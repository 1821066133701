<template>
  <div
    class="ribbon-indicator noPrint"
    :class="{
      'admin-ribbon': user && (user.isAdmin || user.isManagingAdmin),
      'provider-ribbon': user && user.isProvider,
      'patient-ribbon': user && user.isPatient,
    }"
  >
    {{ user ? "Welcome" : "" }}
    <span class="user-name"
      >{{ user && user.last }}, {{ user && user.first }}</span
    >
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
};
</script>
<style scoped>
.ribbon-indicator {
  display: none;
  width: 100%;
  height: 30px;
  margin-top: -1.5rem;
  margin-bottom: 1.5rem;
  color: #000000;
  line-height: 30px;
  text-shadow: 0 0 8px #ffffff;
  font-size: 16px;
  padding-left: 8px;
}

.user-name {
  font-weight: bold;
}

.admin-ribbon {
  background-color: #789fbb;
}
.provider-ribbon {
  background-color: #8abca7;
}

.patient-ribbon {
  background-color: #dbb13b;
}
</style>

<template>
  <div>
    <strong>Version: {{ version }}</strong>
  </div>
</template>

<script>
export default {
  computed: {
    version: () => {
      let pack = require("../../public/version.json");
      if (pack) {
        return pack.version;
      }
      return "N/A";
    },
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{staticClass:"conversation-users-modal",attrs:{"name":"conversationUsersModal","transition":"pop-out","width":500,"focus-trap":true,"height":500}},[_c('div',{staticClass:"position-relative modal-outer-container"},[_c('div',{staticClass:"modal-header border-bottom"},[_c('div',{staticClass:"ml-3 pl-2"},[_vm._v(" "+_vm._s(_vm.modalType == "remove" ? "Remove" : "Add")+" Conversation Participants ")]),_c('button',{staticClass:"btn border-0",on:{"click":_vm.closeModal}},[_c('i',{staticClass:"fas fa-times fa-lg"})])]),_c('div',{staticClass:"body-inner-container"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"col-12 mb-1"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchKey),expression:"searchKey"}],staticClass:"form-control",attrs:{"type":"text","placeholder":`Search by ${
              _vm.modalType == 'remove' ? 'participant' : 'user'
            } name`},domProps:{"value":(_vm.searchKey)},on:{"input":function($event){if($event.target.composing)return;_vm.searchKey=$event.target.value}}})]),_c('div',{staticClass:"col-12"},[(_vm.modalType == 'remove')?_c('ul',{staticClass:"participant-list border mt-2 rounded"},[(!_vm.filteredParticipants.length && _vm.searchKey)?_c('li',{staticClass:"text-center p-3 h3 m-0"},[_vm._v(" No participant found. ")]):_vm._e(),(!_vm.filteredParticipants.length && !_vm.searchKey)?_c('li',{staticClass:"text-center p-3 h3 m-0"},[_vm._v(" No participants to remove. ")]):_vm._e(),_vm._l((_vm.filteredParticipants),function(participant){return _c('li',{key:participant.id},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"user-image-with-status"},[_c('img',{staticClass:"user-image",attrs:{"src":participant.user.avatar || _vm.profileImagePlaceholder}}),_c('div',{staticClass:"user-status",class:{
                      online: _vm.isOnline(
                        participant.user && participant.user.id
                      ),
                      offline: !_vm.isOnline(
                        participant.user && participant.user.id
                      ),
                    }})]),_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(participant.name)+" ")]),_c('span',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(participant.role)+" ")])]),_c('div',{staticClass:"ml-auto"},[(_vm.isParticipant(participant))?_c('button',{staticClass:"btn btn-outline-danger",attrs:{"type":"button"},on:{"click":function($event){return _vm.removeParticipant(participant)}}},[(
                        _vm.removeConversationParticipantLoading == participant.id
                      )?_c('i',{staticClass:"fas fa-spin fa-circle-notch"}):_c('i',{staticClass:"fas fa-minus"})]):_vm._e()])])])})],2):_c('ul',{staticClass:"participant-list border mt-2 rounded"},[(!_vm.usersLoading && !_vm.filteredUsers.length && _vm.searchKey)?_c('li',{staticClass:"text-center p-3 h3 m-0"},[_vm._v(" No users found. ")]):_vm._e(),(!_vm.usersLoading && !_vm.filteredUsers.length && !_vm.searchKey)?_c('li',{staticClass:"text-center p-3 h3 m-0"},[_vm._v(" No users to add. ")]):_vm._e(),_vm._l((_vm.filteredUsers),function(user){return _c('li',{key:user.user_id},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"user-image-with-status"},[_c('img',{staticClass:"user-image",attrs:{"src":user.profile_picture || _vm.profileImagePlaceholder}}),_c('div',{staticClass:"user-status",class:{
                      online: _vm.isOnline(user.user_id),
                      offline: !_vm.isOnline(user.user_id),
                    }})]),_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(user.name)+" ")]),_c('span',{staticClass:"text-muted small font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getRoleName(user.roleId))+" ")])]),_c('div',{staticClass:"ml-auto"},[(!_vm.isParticipant(user))?_c('button',{staticClass:"btn btn-outline-blue",attrs:{"type":"button"},on:{"click":function($event){return _vm.addParticipant(user)}}},[(_vm.addConversationParticipantLoading == user.user_id)?_c('i',{staticClass:"fas fa-spin fa-circle-notch"}):_c('i',{staticClass:"fas fa-plus"})]):_vm._e()])])])})],2)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }